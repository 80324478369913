import React from 'react'

import { ClassNames } from '@emotion/react'
import { Disclosure, Transition } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@radix-ui/react-icons'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import tw from 'twin.macro'

import { Section } from '../../section'
import ProductSlider from '../category/product-slider'

const ProductDetails = ({ drillPatterns, height, priceRange }: HeroProps) => {
  const noHeights = height?.find((h) => h === 'NA')
  const formatHeights = !noHeights
    ? height
        ?.map((h: string) => {
          return `${h} mm`
        })
        .join(', ')
    : null

  return (
    <Disclosure
      tw="block border-dashed border-b pt-12 pb-6"
      as="div"
      defaultOpen={true}
    >
      {({ open }) => (
        <>
          <Disclosure.Button tw="w-full">
            <div tw="flex justify-between">
              <div tw="flex">
                <p tw="text-lg text-gray-700 leading-none">Product details</p>
              </div>
              <div tw="flex items-center text-gray-400">
                {open ? (
                  <MinusIcon tw="text-gray-700" />
                ) : (
                  <PlusIcon tw="text-gray-700" />
                )}
              </div>
            </div>
          </Disclosure.Button>
          <ClassNames>
            {({ css }) => (
              <Transition
                show={open}
                enter={css(tw`transition duration-100 ease-out`)}
                enterFrom={css(tw`scale-95 opacity-0`)}
                enterTo={css(tw`scale-100 opacity-100`)}
                leave={css(tw`transition duration-75 ease-out`)}
                leaveFrom={css(tw`scale-100 opacity-100`)}
                leaveTo={css(tw`scale-95 opacity-0`)}
              >
                <Disclosure.Panel tw="flex flex-col text-gray-700 font-light text-base">
                  <div tw="flex flex-col py-6">
                    <p tw="pb-4">Handle Positions:</p>
                    <div tw="flex flex-row">
                      {drillPatterns?.map((dp: any, idx) => (
                        <div tw="w-1/6 pr-2" key={idx}>
                          <img alt={dp.title} src={dp.image.file.url} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div tw="flex flex-col md:(flex-row)">
                    <div tw="w-full pb-6 md:(w-full pb-0)">
                      {formatHeights && (
                        <p tw="pb-4">Height: {formatHeights}</p>
                      )}
                      <p>
                        Price Range: <span tw="font-normal">{priceRange}</span>
                      </p>
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>
            )}
          </ClassNames>
        </>
      )}
    </Disclosure>
  )
}

interface HeroProps {
  description?: string
  drillPatterns?: {
    title?: string
    image?: {
      file?: {
        url: string
      }
    }
  }[]
  height?: string[]
  imageMain?: {
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }
  imageMainClose?: {
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }
  optionKey?: string
  priceRange?: string
}

const HeroHandle = ({
  description,
  drillPatterns,
  height,
  imageMain,
  imageMainClose,
  optionKey,
  priceRange,
}: HeroProps) => {
  return (
    <Section padding="none">
      <div tw="pt-4 px-4 md:(hidden)">
        <div tw="flex items-end space-x-2 mb-4">
          <h1 tw="font-thin text-gray-900 text-4xl oldstyle-nums">
            {optionKey}
          </h1>
        </div>
        <div tw="flex items-end space-x-2 mb-4 ">
          <h2 tw="font-light text-gray-900 text-lg tracking-widest uppercase">
            {description}
          </h2>
        </div>
      </div>
      <div tw="flex flex-col relative md:(grid grid-cols-8 gap-x-4)">
        <div tw="col-span-4 md:(sticky)">
          <ProductSlider
            imageMain={imageMain}
            imageMainClose={imageMainClose}
          />
        </div>

        <div tw="px-4 bg-white md:(col-span-4 px-0)">
          <div tw="flex items-start h-full pt-8 md:(w-11/12 pt-12) lg:(pl-24)">
            <div tw="w-full relative">
              <div tw="hidden items-end space-x-2 mb-8 md:(flex)">
                <p tw="font-thin text-gray-900 text-4xl oldstyle-nums leading-none">
                  {optionKey}
                </p>
              </div>
              <div tw="hidden items-end space-x-2 md:(flex)">
                <p tw="font-light text-gray-900 text-xl leading-7 tracking-widest uppercase md:(text-xl)">
                  {description}
                </p>
              </div>
              <ProductDetails
                drillPatterns={drillPatterns}
                height={height}
                priceRange={priceRange}
              />
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export { HeroHandle }
