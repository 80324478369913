import React, { useEffect, useState } from 'react'

import { ClassNames } from '@emotion/react'
import { Disclosure, Transition } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@radix-ui/react-icons'
import { motion } from 'framer-motion'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import queryString from 'query-string'
import tw from 'twin.macro'

import { Section } from '../../section'
import { HandleConfigurator } from '../category/handle-configurator'
import ProductSlider from '../category/product-slider'

const ProductDetails = ({
  drillPatterns,
  finish,
  material,
  priceRange,
  texture,
}: HeroProps) => {
  const noDrillPatterns = drillPatterns?.find((dp) => dp.title === 'NA')

  return (
    <Disclosure tw="block border-dashed border-b pt-12 pb-6" as="div">
      {({ open }) => (
        <>
          <Disclosure.Button tw="w-full">
            <div tw="flex justify-between">
              <div tw="flex">
                <p tw="text-lg text-gray-700 leading-none">Product details</p>
              </div>
              <div tw="flex items-center text-gray-400">
                {open ? (
                  <MinusIcon tw="text-gray-700" />
                ) : (
                  <PlusIcon tw="text-gray-700" />
                )}
              </div>
            </div>
          </Disclosure.Button>
          <ClassNames>
            {({ css }) => (
              <Transition
                show={open}
                enter={css(tw`transition duration-100 ease-out`)}
                enterFrom={css(tw`scale-95 opacity-0`)}
                enterTo={css(tw`scale-100 opacity-100`)}
                leave={css(tw`transition duration-75 ease-out`)}
                leaveFrom={css(tw`scale-100 opacity-100`)}
                leaveTo={css(tw`scale-95 opacity-0`)}
              >
                <Disclosure.Panel tw="flex flex-col pt-8 text-gray-700 font-light text-base">
                  <div tw="flex flex-col pb-6 md:(flex-row)">
                    <div tw="w-full pb-6 md:(w-1/2 pb-0)">
                      <p tw="pb-6">
                        Finish: <span tw="font-normal">{finish}</span>
                      </p>
                      <p>
                        Price Range: <span tw="font-normal">{priceRange}</span>
                      </p>
                    </div>
                    <div tw="w-full md:(w-1/2)">
                      <p tw="pb-6">
                        Material:{' '}
                        <span tw="font-normal">{material?.title}</span>
                      </p>
                      <p>
                        Texture: <span tw="font-normal">{texture}</span>
                      </p>
                    </div>
                  </div>
                  {!noDrillPatterns && (
                    <div tw="flex flex-col pb-6">
                      <p tw="pb-4">Available for:</p>
                      <div tw="flex flex-row">
                        {drillPatterns?.map((dp: any, idx) => (
                          <div tw="w-1/6 pr-2" key={idx}>
                            <img alt={dp.title} src={dp.image?.file.url} />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div tw="flex flex-col">
                    <p>Composition:</p>
                    <div tw="flex flex-col pt-4 pl-10 md:(flex-row pl-16)">
                      <ol tw="list-decimal w-full md:(w-1/2)">
                        {material?.diagramReference?.map((dr: any, idx) => (
                          <li key={idx}>{dr}</li>
                        ))}
                      </ol>
                      <div tw="w-full pt-6 pl-6 md:(w-1/2 pt-0)">
                        <img
                          alt={material?.title}
                          tw="h-20 md:(h-auto)"
                          src={material?.diagram?.file?.url}
                        />
                      </div>
                    </div>
                  </div>
                </Disclosure.Panel>
              </Transition>
            )}
          </ClassNames>
        </>
      )}
    </Disclosure>
  )
}

interface HeroProps {
  description?: string
  drillPatterns?: {
    title?: string
    image?: {
      file?: {
        url: string
      }
    }
  }[]
  finish?: string
  handle?: string[]
  imagesBlank?: {
    title?: string
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }[]
  imageBlankFront?: {
    title?: string
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }
  imageBlankSide?: {
    title?: string
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }
  imageHandleless?: {
    title?: string
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }
  imagesHandleless?: {
    title?: string
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }[]
  imageHandlelessSide?: {
    title?: string
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }
  imageMain?: {
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }
  imageMainClose?: {
    localFile?: {
      childImageSharp?: {
        gatsbyImageData?: IGatsbyImageData
      }
    }
  }
  location?: Location
  material?: {
    title?: string
    diagramReference?: string[]
    diagram?: {
      file?: {
        url: string
      }
    }
  }
  optionKey?: string
  priceRange?: string
  relatedHandles?: {
    category?: {
      title?: string
    }
    description?: string
    imageMain?: {
      title?: string
      localFile?: {
        childImageSharp?: {
          gatsbyImageData?: IGatsbyImageData
        }
      }
    }
    imageOverlay?: {
      title?: string
      localFile?: {
        childImageSharp?: {
          gatsbyImageData?: IGatsbyImageData
        }
      }
    }
    imageOverlaySide?: {
      title?: string
      localFile?: {
        childImageSharp?: {
          gatsbyImageData?: IGatsbyImageData
        }
      }
    }
    optionKey?: string
  }[]
  rotatingVideo?: any
  texture?: string
}

const HeroFront = ({
  description,
  drillPatterns,
  finish,
  handle,
  imageBlankFront,
  imageBlankSide,
  imageHandleless,
  imageHandlelessSide,
  imageMain,
  imageMainClose,
  location,
  material,
  optionKey,
  priceRange,
  relatedHandles,
  rotatingVideo,
  texture,
}: HeroProps) => {
  const [handleOverlay, setHandleOverlay] = useState({})
  const [isConfiguring, setIsConfiguring] = useState(false)
  const [updateHash, setUpdateHash] = useState(false)

  const searchParams: { [index: string]: any } = queryString.parse(
    location?.hash ?? '',
  )
  const imagesBlank =
    imageBlankFront && imageBlankSide ? [imageBlankFront, imageBlankSide] : null

  const imagesHandleless =
    imageHandleless && imageHandlelessSide
      ? [imageHandleless, imageHandlelessSide]
      : null

  relatedHandles = imagesBlank
    ? relatedHandles?.filter((rh) => rh.imageOverlay)
    : relatedHandles

  const defaultHandles = relatedHandles?.filter(
    (rh) => !rh.category?.title?.includes('Integrated'),
  )

  const integratedHandles = relatedHandles?.filter(
    (rh) => rh.category?.title?.includes('Integrated'),
  )

  const defaultSelectedHandleStyle = parseInt(
    defaultHandles?.[0]?.optionKey ?? '',
  )
  const defaultSelectedIntegratedStyle = parseInt(
    integratedHandles?.[0]?.optionKey ?? '',
  )

  const [selectedHandleType, setSelectedHandleType] = React.useState<
    'handle' | 'integrated' | 'handleless'
  >('handle')

  const [selectedHandlelessStyle, setSelectedHandlelessStyle] = React.useState<
    'horizontal' | 'vertical'
  >('horizontal')

  const [selectedHandleStyle, setSelectedHandleStyle] = React.useState<
    number | null
  >(defaultSelectedHandleStyle)

  useEffect(() => {
    const dict: {
      [x: number]: {
        title?: string
        localFile?: {
          childImageSharp?: {
            gatsbyImageData?: IGatsbyImageData
          }
        }
      }[]
    } = {}
    relatedHandles?.forEach(
      (handle: {
        category?: {
          title?: string
          image?: {
            title?: string
            file?: {
              url?: string
            }
          }
        }
        color?: {
          title?: string
        }
        description?: string
        imageMain?: {
          title?: string
          localFile?: {
            childImageSharp?: {
              gatsbyImageData?: IGatsbyImageData
            }
          }
        }
        imageOverlay?: {
          title?: string
          localFile?: {
            childImageSharp?: {
              gatsbyImageData?: IGatsbyImageData
            }
          }
        }
        imageOverlaySide?: {
          title?: string
          localFile?: {
            childImageSharp?: {
              gatsbyImageData?: IGatsbyImageData
            }
          }
        }
        optionKey?: string
      }) => {
        const overlayHandleImages = []
        overlayHandleImages?.push(
          handle?.imageOverlay,
          handle?.imageOverlaySide,
        )
        dict[parseInt(handle?.optionKey ?? '')] = overlayHandleImages as any
      },
    )
    setHandleOverlay(dict)
  }, [])

  useEffect(() => {
    if (searchParams?.handleType) {
      setSelectedHandleType(searchParams?.handleType)
      setIsConfiguring(true)
    }
    if (searchParams?.handleStyle) {
      setSelectedHandleStyle(parseInt(searchParams?.handleStyle))
    }
  }, [])

  const updateFrontInfo = (field: any, func: (x: any) => void, value: any) => {
    func(value)
    if (field === 'handleType') {
      if (value === 'handle') {
        setSelectedHandleStyle(defaultSelectedHandleStyle)
      } else if (value === 'integrated') {
        setSelectedHandleStyle(defaultSelectedIntegratedStyle)
      } else {
        setSelectedHandleStyle(null)
      }
    }
    setIsConfiguring(true)
    setUpdateHash(true)
  }

  useEffect(() => {
    if (updateHash) {
      const setup = []
      if (selectedHandleType) {
        setup.push(`handleType=${selectedHandleType}`)
      }
      if (selectedHandleStyle) {
        setup.push(`handleStyle=${selectedHandleStyle}`)
      }
      if (setup.length >= 1) {
        window.location.hash = setup.join('&')
      }
    }
    setUpdateHash(false)
  }, [updateHash])

  return (
    <Section padding="none">
      <div tw="pt-4 px-4 md:(hidden)">
        <div tw="flex items-end space-x-2 mb-4">
          <h1 tw="font-thin text-gray-900 text-4xl oldstyle-nums">
            {optionKey}
          </h1>
        </div>
        <div tw="flex items-end space-x-2 mb-4 ">
          <h2 tw="font-light text-gray-900 text-lg tracking-widest uppercase">
            {description}
          </h2>
        </div>
      </div>
      <div tw="flex flex-col relative md:(grid grid-cols-8 gap-x-4)">
        <div tw="col-span-4 md:(sticky)">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <ProductSlider
              handleOverlay={handleOverlay}
              imageMain={imageMain}
              imageMainClose={imageMainClose}
              imagesBlank={imagesBlank ?? undefined}
              imagesHandleless={imagesHandleless ?? undefined}
              isConfiguring={isConfiguring}
              isHandleless={selectedHandleType === 'handleless'}
              rotatingVideo={rotatingVideo}
              selectedHandleStyle={selectedHandleStyle}
            />
          </motion.div>
        </div>

        <div tw="px-4 bg-white md:(col-span-4 px-0)">
          <div tw="flex items-start h-full pt-8 md:(w-11/12 pl-8 pt-12) lg:(pl-24)">
            <div tw="w-full relative">
              <div tw="hidden items-end space-x-2 mb-8 md:(flex)">
                <p tw="font-thin text-gray-900 text-4xl oldstyle-nums leading-none">
                  {optionKey}
                </p>
              </div>
              <div tw="hidden items-end space-x-2 md:(flex)">
                <p tw="font-light text-gray-900 text-xl leading-7 tracking-widest uppercase md:(text-xl)">
                  {description}
                </p>
              </div>
              <ProductDetails
                drillPatterns={drillPatterns}
                finish={finish}
                material={material}
                priceRange={priceRange}
                texture={texture}
              />

              <div tw="relative">
                {((defaultHandles || integratedHandles) && imagesBlank) ||
                imagesHandleless ? (
                  <HandleConfigurator
                    defaultHandles={defaultHandles}
                    handle={handle ?? []}
                    imagesHandleless={imagesHandleless ?? undefined}
                    integratedHandles={
                      integratedHandles?.length ? integratedHandles : undefined
                    }
                    selectedHandleStyle={selectedHandleStyle as number}
                    selectedHandleType={selectedHandleType}
                    selectedHandlelessStyle={selectedHandlelessStyle}
                    setSelectedHandleStyle={setSelectedHandleStyle}
                    setSelectedHandleType={setSelectedHandleType}
                    setSelectedHandlelessStyle={setSelectedHandlelessStyle}
                    updateFrontInfo={updateFrontInfo}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export { HeroFront }
